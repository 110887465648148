import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Particles from "react-tsparticles";

import config from "../configs/particles.json";

const Home = () => {
  return (
    <Fragment>
      <Helmet>
        <title>noah.</title>
      </Helmet>
      <Particles id="tsparticles" options={config} />
    </Fragment>
  );
};

export default Home;
